.skeleton-trans {
  animation: skeleton-flash 2s ease-in-out infinite;
  opacity: .5;
}

@keyframes skeleton-flash {

  0% {
    background-color: color(accent, 4);
  }

  50% {
    background-color: color(accent, 1);
  }

  100% {
    background-color: color(accent, 4);
  }
}

.opacity-trans {
  animation: opacity-flash 2s ease-in-out infinite;
}

@keyframes opacity-flash {

  0% {
    opacity: 1;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}