$palettes: (
  mono: (
    white: #ffffff,
    dark: #1b1b1b,
    border: #dee2e6,
  ),
  accent: (
    1: #585757,
    2: #dee2e6,
    3: #e9ecee,
    4: #f1f3f5,
    5: #f5f8fa,
    6: #f8f9fa,
    7: #f0f6fa,
    8: #7e7e7e,
    /* TODO: pick a better color */
    9: #deebff,
  ),
  brand: (
    Pink: #e10259,
    Blue: #267ea1,
    Yellow: #ffc800,
    DarkBlue: #01359F,
    Green: #24C95E,
  ),
  link: (
    white: #ffffff,
    hover-blue: #3c6f95,
  ),
  text: (
    blue-dark: #00516d,
    blue: #3c6f95,
    light: #dee2e6,
    grey: #585757,
    dark: #585757,
  ),
  state: (
    error: #ea4646,
    success: #148550,
    warning: #ffbc26,
    focus: #f5f8fa,
    on: #19d9ca,
    off: #ff618b,
    default: #004f8b,
  ),
  side-nav: (
    blue: #004f8b,
  ),
  shadow: (
    regular: rgba(#000000, .04),
    bold: rgba(#000000, .1),
  ),
);