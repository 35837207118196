body {
  font-family: $font-lato;
  font-weight: $font-weight-regular;
}

strong * {
  font-weight: $font-weight-bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-montserrat;
  font-weight: $font-weight-bold;
  margin-bottom: $space;
  margin-top: 0;
}

h1 {
  font-size: $font-size-h1 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h1;
  }
}

h2 {
  font-size: $font-size-h2 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h2;
  }
}

h3 {
  font-size: $font-size-h3 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h3;
  }
}

h4 {
  font-size: $font-size-h4 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h4;
  }
}

h5 {
  font-size: $font-size-h5 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h5;
  }
}

h6 {
  font-size: $font-size-h6 * .8;

  @include breakpoint(tablet) {
    font-size: $font-size-h6;
  }
}

p {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}