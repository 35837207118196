@import '../../scss/index.scss';

$heading-height: rem(24px);

.toast.Toastify__toast {
  background-color: color(mono, white);
  color: color(mono, dark);
  padding: 0;

  &:before {
    align-items: center;
    color: color(mono, white);
    content: '';
    display: flex;
    font-size: $small-font-size;
    height: $heading-height;
    padding: 0 $space-l;
    position: absolute;
    width: 100%;
  }

  & > div {
    padding: ($heading-height + $space-l) $space-l $space-l $space-l;
  }

  &--default:before {
    background-color: color(brand, pink);
  }

  &--success:before {
    background-color: color(state, success);
  }

  &--info:before {
    background-color: color(brand, blue);
  }

  &--warning:before {
    background-color: color(state, warning);
  }

  &--error:before {
    background-color: color(state, error);
  }

  .Toastify__close-button {
    color: color(mono, white);
    opacity: .5;
    position: absolute;
    right: rem(6px);
    top: rem(4px);

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

}

@keyframes zoomIn {

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  100% {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}