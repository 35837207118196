@import '../../../../scss/index.scss';

.wrap {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  content: '';
  height: 10em;
  width: 10em;
}

.spinner {
  animation: load8 $trans-long infinite linear;
  border-bottom: 1.1em solid color(brand, pink);
  border-left: 1.1em solid rgba(color(brand, pink), 0);
  border-right: 1.1em solid color(brand, pink);
  border-top: 1.1em solid color(brand, pink);
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  z-index: 30;
}

@keyframes load8 {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.blue {
  border-bottom: 1.1em solid color(brand, blue);
  border-left: 1.1em solid rgba(color(brand, blue), 0);
  border-right: 1.1em solid color(brand, blue);
  border-top: 1.1em solid color(brand, blue);
}

.large {
  font-size: 6px;
}

.small {
  font-size: 1.5px;
}