$font-montserrat: 'Montserrat', sans-serif;
$font-lato: 'Lato', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

$base-font-size: 16px;
$small-font-size: $base-font-size * .7;

$font-size-h1: $base-font-size * 3;
$font-size-h2: $base-font-size * 2;
$font-size-h3: $base-font-size * 1.5;
$font-size-h4: $base-font-size * 1.2;
$font-size-h5: $base-font-size * 1.1;
$font-size-h6: $base-font-size * 1;