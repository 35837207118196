@import '../node_modules/normalize.css/normalize.css';

@import './src/scss/index.scss';

@import './src/scss/components/index.scss';

$rem-fallback: true;

html {
  @include rem-baseline;
}

* {
  box-sizing: border-box;
  outline: none;
  user-select: text;
}

body {
  color: color(text, blue-dark);
}

*[data-title] {
  position: relative;

  @include breakpoint(large) {

    &:after {
      background-color: color(mono, dark);
      border-radius: .4em;
      color: color(mono, white);
      content: attr(data-title);
      font-size: 11px;
      left: calc(100% + #{$space});
      opacity: 0;
      padding: $space-xs $space-s;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity $trans-x-short ease-in-out;
      white-space: nowrap;
    }

    &:hover:after {
      opacity: 1;
    }
  }
}

a {
  color: color(brand, Pink);
  transition: color $trans-short ease-in-out;

  &:hover,
  &:focus {
    color: lighten(color(brand, Pink), 15);
  }
}