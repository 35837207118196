@import './scss/index.scss';

.wrap {
  min-height: 100vh;
  min-width: 100vw;

  &:before {
    background: color(mono, white);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $trans-short ease-out;
    z-index: 40;
  }

  & > div:first-child {
    opacity: 0;
    pointer-events: none;
    transition: opacity $trans-short ease-out;
    z-index: 40;
  }

  &.loading {
    height: 100vh;
    position: relative;
    width: 100vw;

    &:before {
      opacity: 1;
      pointer-events: all;
    }

    & > div:first-child {
      opacity: 1;
      pointer-events: all;
    }
  }
}